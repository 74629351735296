<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 17:38:22
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
</style>
<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "ProjectCenter",
  methods: {},
};
</script>